import { createCanvas, loadImage, CanvasRenderingContext2D } from "canvas"

import {
  PixelType,
  PixelData,
  isPixelWithinCircle,
  hexToRgb,
  drawCircle,
} from "./utils"

import { inputSize, outputSize, framedSize, colors } from "./params"
import layersMetadata from "../data/metadata.json"
import layersPaths from "../data/traits.json"

export const determinePixelType = (
  r: number,
  g: number,
  b: number,
  a: number,
  x: number,
  y: number,
  canvasSize: number,
  params: any
): { type: PixelType; color?: string } => {
  if (a < 0.1) {
    const centerX = canvasSize / 2
    const centerY = canvasSize / 2
    const radius = canvasSize / 2 + 0.5
    const isCircle = isPixelWithinCircle(x, y, centerX, centerY, radius)

    return {
      type: isCircle ? PixelType.Circle : PixelType.Empty,
      color: isCircle ? colors.White : undefined,
    }
  } else {
    const threshold = params.thresholdColor * 255

    const rgbColor = { r, g, b }
    let closestColor = params.foregroundColor // Default to foreground color
    let minDistance = Number.MAX_VALUE
    const paletteColors = Object.keys(colors).slice(3) // Skip B&W + Orange colors

    for (const key of paletteColors as Array<keyof typeof colors>) {
      const paletteColor = hexToRgb(colors[key])
      const distance = Math.sqrt(
        (rgbColor.r - paletteColor.r) ** 2 +
          (rgbColor.g - paletteColor.g) ** 2 +
          (rgbColor.b - paletteColor.b) ** 2
      )

      if (distance < minDistance && distance < threshold) {
        closestColor = colors[key]
        minDistance = distance
      }
    }

    const brightness = (r + g + b) / (3 * 256)

    if (brightness < params.thresholdEmpty) {
      return { type: PixelType.Empty, color: closestColor } // Now we assign the closest color or default to white
    } else if (brightness < params.thresholdSemifilled) {
      return { type: PixelType.SemiFilled, color: closestColor }
    } else {
      return { type: PixelType.Filled, color: closestColor }
    }
  }
}

const generatePunkFromLayers = async (punkNumber: number, params: any) => {
  // Create a canvas
  const canvas = createCanvas(framedSize, framedSize)
  const ctx = canvas.getContext("2d")

  // Prepare the layers info
  const metadata = layersMetadata[punkNumber]
  const layers = layersPaths as any

  // Load base layer first
  const basePath =
    metadata.type === "Human"
      ? layers[`${metadata.type}-${metadata.skin}-${metadata.gender}`]
      : metadata.type === "Ape"
      ? layers[`${metadata.type}-${metadata.skin}`]
      : layers[metadata.type]
  const baseLayer = await loadImage(basePath)
  ctx.drawImage(baseLayer, 0, 0, inputSize, inputSize)

  // Load and apply each accessory layer on top of the base
  if (metadata?.accessories) {
    for (const accessory of metadata.accessories.split(" / ")) {
      const name =
        layers?.[accessory] || layers[`${accessory}-${metadata.gender}`]
      const layer = await loadImage(name)
      ctx.drawImage(layer, 0, 0, inputSize, inputSize) // adjust dimensions as necessary
    }
  }

  return ctx
}

export const processImage = async (punkNumber: number, params: any) => {
  const ctx = await generatePunkFromLayers(punkNumber, params)

  const pixelData: PixelData[][] = []

  for (let y = 0; y < inputSize; y++) {
    const row: PixelData[] = []
    for (let x = 0; x < inputSize; x++) {
      const imageData = ctx.getImageData(x, y, 1, 1)
      const [r, g, b, a] = imageData.data
      const pixelType = determinePixelType(r, g, b, a, x, y, inputSize, params)
      row.push(pixelType)
    }
    pixelData.push(row)
  }

  return pixelData
}

export const drawBeyondPunk = (
  pixelData: PixelData[][],
  ctx: CanvasRenderingContext2D,
  params: any
) => {
  const circleColor =
    params.circleColors[Math.floor(Math.random() * params.circleColors.length)]

  // Fill the background with black + the circular border
  ctx.fillStyle = params.backgroundColor
  ctx.fillRect(0, 0, framedSize, framedSize)

  const scaleFactor = outputSize / inputSize
  const pixelInner = scaleFactor * 0.75 // New inner square size based on scale
  const semifilledInner = scaleFactor * 0.25 // New size for the inner square of semi-filled pixels
  const offsetX = (framedSize - outputSize) / 2
  const offsetY = (framedSize - outputSize) / 2

  for (let y = 0; y < inputSize; y++) {
    for (let x = 0; x < inputSize; x++) {
      if (x <= 7 && y === 23) continue // For aesthetic reasons, skip the bottom left pixel of the Punk's neck

      const pixelInfo = pixelData[y][x]
      const xPos = x * (outputSize / inputSize) + offsetX
      const yPos = y * (outputSize / inputSize) + offsetY
      const centerX = xPos + scaleFactor / 2
      const centerY = yPos + scaleFactor / 2

      switch (pixelInfo.type) {
        case PixelType.Circle:
          ctx.fillStyle = circleColor
          drawCircle(ctx, centerX, centerY, pixelInner / 2)
          break
        case PixelType.Filled:
          ctx.fillStyle = pixelInfo?.color || params.foregroundColor
          drawCircle(ctx, centerX, centerY, pixelInner / 2)
          break
        case PixelType.SemiFilled:
          ctx.fillStyle = pixelInfo?.color || params.foregroundColor
          drawCircle(ctx, centerX, centerY, semifilledInner / 2)
          break
      }
    }
  }

  // // For the 2 laser eyes traits, we need to draw a few pixels outside the typical canvas limits
  // if (params.traits.includes("Laser Eyes")) {
  //   ctx.fillStyle = colors.Yellow

  //   for (let x = 0; x < 3; x++) {
  //     for (let y = 0; y < 2; y++) {
  //       drawCircle(ctx, 24 + x, 12 + y, pixelInner / 2)
  //     }
  //   }
  // } else if (params.traits.includes("Glowing Laser Eyes")) {
  //   ctx.fillStyle = colors.Yellow

  //   for (let i = 0; i < 3; i++) {
  //     drawCircle(ctx, 24 + i, 22 + i, pixelInner / 2)
  //     drawCircle(ctx, 22 + i, 24 + i, pixelInner / 2)
  //   }
  // }
}

export const createBeyondPunk = async (punkNumber: number, params: any) => {
  const pixelData = await processImage(punkNumber, params)

  const canvas = createCanvas(framedSize, framedSize)
  const ctx = canvas.getContext("2d")
  drawBeyondPunk(pixelData, ctx, params)

  return canvas.toDataURL("image/png")
}
