import { useCallback, useEffect, useState } from "react"
import "./App.css"
import { createBeyondPunk } from "./functions"
import {
  colors,
  thresholdEmpty as threshEmpty,
  thresholdSemifilled as threshSemi,
  thresholdColor as threshColor,
  framedSize,
} from "./functions/params"

const NumberInput = ({ value, callback, step }: any) => {
  return (
    <input
      type="number"
      step={step}
      value={value}
      onChange={(e) => callback(parseFloat(e.target.value))}
      style={{
        marginLeft: "10px",
        fontSize: "1.5em", // Larger font size makes the arrows bigger
        width: "80px",
      }}
    />
  )
}

const ColorOptions = ({ value, callback }: any) => {
  return (
    <select
      value={value}
      onChange={(e) => callback(e.target.value)}
      style={{ marginLeft: "10px", fontSize: "1.5em" }}
    >
      <option value={colors.Black}>Black</option>
      <option value={colors.White}>White</option>
      <option value={colors.Orange}>Orange</option>
      <option value={colors.Red}>Red</option>
      <option value={colors.Green}>Green</option>
      <option value={colors.Purple}>Purple</option>
      <option value={colors.Blue}>Blue</option>
      <option value={colors.Magenta}>Magenta</option>
      <option value={colors.Yellow}>Yellow</option>
    </select>
  )
}

function App() {
  const [punkNumber, setPunkNumber] = useState(0)
  const [thresholdEmpty, setThresholdEmpty] = useState(threshEmpty)
  const [thresholdSemifilled, setThresholdSemifilled] = useState(threshSemi)
  const [thresholdColor, setThresholdColor] = useState(threshColor)
  const [foregroundColor, setForegroundColor] = useState(colors.White)
  const [backgroundColor, setBackgroundColor] = useState(colors.Black)
  const [circleColors, setCircleColors] = useState(colors.Orange)
  const [imageUrl, setImageUrl] = useState("")

  const imageSize = framedSize // "500px"
  const doTest = false
  const testFolder = doTest ? "test/" : ""

  const handlePixelate = useCallback(async () => {
    const params = {
      thresholdEmpty,
      thresholdSemifilled,
      thresholdColor,
      foregroundColor,
      backgroundColor,
      circleColors: [circleColors],
    }
    const dataUrl = await createBeyondPunk(punkNumber, params)

    console.log("Generated punk:", punkNumber)
    setImageUrl(dataUrl)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    punkNumber,
    thresholdEmpty,
    thresholdSemifilled,
    thresholdColor,
    foregroundColor,
    backgroundColor,
    circleColors,
  ])

  useEffect(() => {
    handlePixelate()
  }, [handlePixelate])

  const DownloadButton = ({ folder = "input" }) => {
    const handleDownload = () => {
      const link = document.createElement("a")
      link.href =
        folder === "input"
          ? `/input/${testFolder}punk${punkNumber}.png`
          : imageUrl
      link.download = `punk${punkNumber}-${folder}.png`
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }

    return <button onClick={handleDownload}>Download</button>
  }

  return (
    <div>
      <h1>Beyond Punk Generator</h1>
      <div
        style={{
          justifyContent: "flex-start",
          display: "flex",
          flexWrap: "wrap",
          margin: "20px",
        }}
      >
        <div style={{ marginRight: "40px" }}>
          Punk:
          <NumberInput value={punkNumber} callback={setPunkNumber} step={1} />
        </div>
        <div style={{ marginRight: "40px" }}>
          Threshold Empty:
          <NumberInput
            value={thresholdEmpty}
            callback={setThresholdEmpty}
            step={"0.1"}
          />
        </div>
        <div style={{ marginRight: "40px" }}>
          Threshold Semifilled:
          <NumberInput
            value={thresholdSemifilled}
            callback={setThresholdSemifilled}
            step={"0.1"}
          />
        </div>
        <div style={{ marginRight: "40px", color: colors.Orange }}>
          Threshold Colored:
          <NumberInput
            value={thresholdColor}
            callback={setThresholdColor}
            step={"0.1"}
          />
        </div>
      </div>
      <div
        style={{
          justifyContent: "flex-start",
          display: "flex",
          flexWrap: "wrap",
          margin: "20px",
        }}
      >
        <div style={{ marginRight: "40px" }}>
          Background:
          <ColorOptions value={backgroundColor} callback={setBackgroundColor} />
        </div>

        <div style={{ marginRight: "40px" }}>
          Foreground:
          <ColorOptions value={foregroundColor} callback={setForegroundColor} />
        </div>

        <div style={{ marginRight: "40px" }}>
          Circle:
          <ColorOptions value={circleColors} callback={setCircleColors} />
        </div>
      </div>

      <div
        style={{
          justifyContent: "flex-start",
          display: "flex",
          flexWrap: "wrap",
          marginTop: "60px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            src={`input/${testFolder}punk${punkNumber}.png`}
            alt="Original Punk"
            style={{
              width: imageSize,
              height: imageSize,
              imageRendering: "pixelated",
              marginBottom: "20px",
            }}
          />
          <DownloadButton folder="input" />
        </div>
        <div style={{ margin: "230px 80px 0px 0px", fontSize: "40px" }}>➡️</div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            src={imageUrl}
            alt="Generated Punk"
            style={{
              width: imageSize,
              height: imageSize,
              marginBottom: "20px",
            }}
          />
          <DownloadButton folder="output" />
        </div>
      </div>
    </div>
  )
}

export default App
