import { CanvasRenderingContext2D } from "canvas"

export enum PixelType {
  Circle,
  Empty,
  Filled,
  SemiFilled,
}

export type PixelData = {
  type: PixelType
  color?: string
}

export const isPixelWithinCircle = (
  x: number,
  y: number,
  centerX: number,
  centerY: number,
  radius: number
): boolean => {
  // Adjust x and y to refer to the center of the pixel
  const pixelCenterX = x + 0.5
  const pixelCenterY = y + 0.5

  const distSq = (pixelCenterX - centerX) ** 2 + (pixelCenterY - centerY) ** 2
  const radiusSq = radius ** 1.99

  return distSq <= radiusSq
}

export function hexToRgb(hex: string): { r: number; g: number; b: number } {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex) || []
  return {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16),
  }
}

export function drawCircle(
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  radius: number
) {
  ctx.beginPath()
  ctx.arc(x, y, radius, 0, 2 * Math.PI)
  ctx.fill()
}
