// export const inputFolder = "public/input"
// export const outputFolder = "public/output"
export const inputFolder = "public/input/test"
export const outputFolder = "public/output/test"

export const inputSize = 24 // Original input image size (small, pixelated image)
export const outputSize = 400 // Size of the generated image without frame
export const framedSize = outputSize / 0.85 // Total size including the frame

export const thresholdEmpty = 0.1
export const thresholdSemifilled = 0.5
export const thresholdColor = 0.4

export const colors = {
  Black: "#000000",
  White: "#FFFFFF",
  Orange: "#FF5500",
  Red: "#FE0100",
  Green: "#16D374",
  Purple: "#7F00FF",
  Blue: "#7F00FF",
  Magenta: "#FB2FFC",
  Yellow: "#FCFD45",
}
export const foregroundColor = colors.White
export const backgroundColor = colors.Black
export const circleColors = [colors.Orange] // ["#FF5500", "#F89414", "#FF6600"] // orangeBeyond, orangeBitcoin, orangeOrdinal
